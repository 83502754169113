import React, { useEffect, useState } from 'react';
import { Row, Col, Grid, Link } from 'reaxl';
import VehicleCard from './reusableComponents/VehicleCard';
import { atcBaseUrl } from '../../constants/config';
import { sendClick } from 'reaxl-analytics';

interface Vehicle {
  type: string;
  href: string;
  imageUrl: string;
  taggingLabel: string;
  shortHand: string;
  id: string;
}
const generateVehicleObjects = () => {
  const vehicleTypes = ['Sedan', 'Truck', 'SUV/Crossover', 'Coupe', 'Hatchback', 'Van/Minivan', 'Convertible', 'Wagon'];
  const vehicleObjects: Vehicle[] = [];
  for (const vehicleType of vehicleTypes) {
    const lowerCaseVehicleType = vehicleType.toLowerCase();
    let imageName = lowerCaseVehicleType;
    let hrefName = lowerCaseVehicleType;
    if (vehicleType.includes('/')) {
      // vehicles like 'SUV/Crossover' turn to suv for image and suv-crossover for href
      const splitVehicleType = lowerCaseVehicleType.split('/');
      imageName = splitVehicleType[0];
      hrefName = splitVehicleType.join('-');
    }
    const vehicleObject = {
      type: vehicleType,
      href: atcBaseUrl + '/cars-for-sale/by-owner/' + hrefName,
      imageUrl: atcBaseUrl + '/psx-static/main/psx-react-web/' + imageName + '.webp',
      taggingLabel: 'tile::srp::' + hrefName,
      shortHand: hrefName,
      id: vehicleType + '-id',
    };
    vehicleObjects.push(vehicleObject);
  }
  return vehicleObjects;
};
export default function SellByTypesSection() {
  const [vehicles, setVehicles] = useState<Vehicle[]>([]);
  const onCarPanelClick = (event: any, carType: string) => {
    // event.preventDefault();
    sendClick('atPSXStyleCategoryCTAClick', event, { carType });
  };
  useEffect(() => {
    const vehicleObjects = generateVehicleObjects();
    setVehicles(vehicleObjects);
  }, []);
  return (
    <div className="shop-by-style">
      <Grid>
        <h2>By Owner Cars Selling Near You</h2>
        <Row className="style-container">
          <Col xs={12}>
            <Row>
              {
                vehicles.map((vehicle, index) => (
                  <Col
                    key={vehicle.id}
                    xs={6}
                    sm={3}
                    className="item-column"
                  >
                    <Link
                      href={vehicle.href}
                      onClick={(event: any) => onCarPanelClick(event, vehicle.shortHand)}
                    >
                      <VehicleCard
                        label={vehicle.type}
                        src={vehicle.imageUrl}
                        className="item-card"
                        lazyload
                      />
                    </Link>
                  </Col>
                ))
              }
            </Row>
          </Col>
        </Row>
      </Grid>
    </div>
  );
}
