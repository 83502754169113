import React from 'react';
import {
    Image,
    Panel,
    Text,
} from 'reaxl';

import { InventoryImagePlaceholder } from 'reaxl-molecules';

/**
 *  The VehicleCard component generates a correctly formatted image element.
 *  If a source is not passed to the component then a placeholder element is rendered.
 */
function VehicleCard({
    className = '',
    lazyload = false,
    label,
    sizes = {
        width: 'auto',
        height: 'auto',
    },
    src,
    ...rest
}) {

    let vehicleImage = (
        <Image
            src={src}
            lazyload={lazyload}
            {...sizes}
        />
    );

    // use placeholder if there is no image
    if (!src) {
        vehicleImage = (
            <InventoryImagePlaceholder
                className="padding-horizontal-1"
                height={90}
                width={`${sizes.width}px`}
            />
        );
    }

    return (
        <div
            data-testid="vehicleCard"
            className={`cursor-pointer padding-right-0 display-flex flex-column ${className}`}
            {...rest}
        >
            <Panel className="padding-0 flex-grow">
                <div className="margin-top-2 display-flex flex-column padding-horizontal-2">
                    <div className="display-flex justify-content-center">
                        {vehicleImage}
                    </div>
                    <Text
                        weight="bold"
                        size={200}
                        className="text-center padding-vertical-2"
                    >
                        {label}
                    </Text>
                </div>
            </Panel>
        </div>
    );
}


export default VehicleCard;
